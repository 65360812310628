export default {
  data() {
    return {
      login_required: false,
    }
  },
  mounted() {
    if(this.login_required && !this.isLogin) {
      this.toast('로그인 후 진행 할 수 있습니다.');
      this.$store.commit('setPrevPath', this.$route.fullPath);
      this.$router.replace('/signin');
    }
  },
  methods: {
    clickBack() {
      if (this.$route.query.notif) {
        this.$router.push("/splash");
      } else {
        this.$router.back();
      }
    }
  }
}
