<template>
  <div>
    <div class="pc">
      <div class="lp-divider-main" style="padding-top:96px"></div>
      <div class="padding-top-16 flex-align">
        <div class="body0-bold main">호스팅</div>
        <div class="body4 error margin-left-12">*가입 필수 / 추후 호스팅 단계 변경 가능</div>
      </div>
      <div class="body2 sub3 margin-top-8">서비스를 가동하는데 필요한 서버 및 관리 비용에 해당하는 비용으로 AWS 서비스 기반의 지속성과 안정성을 보장합니다.</div>
      <div class="lp-divider-gray2 padding-top-16"></div>

      <div class="card-wrapper margin-top-52">
        <div class="card-info unselect"
             :class="{'selected-card': inputValue && item.id === inputValue.id}"
             v-for="item in list"
             :key="`hosting-${item.id}`"
             @click="selectItem(item)">
          <div class="flex-align">
            <i class="material-icons gray" :class="{'selected':inputValue && item.id === inputValue.id}">
              {{ inputValue && item.id === inputValue.id ? 'radio_button_checked':'radio_button_unchecked' }}</i>
            <div class="body2 margin-left-8">{{ item.name }} <span v-if="item.price_type===0">(월결제)</span></div>
          </div>
          <div class="margin-top-4">
            <div v-if="item.price_type===0">
              <span class="h6 main">{{ item.price | currencyNum }}</span>
              <span class="body4 main margin-left-2">원</span>
              <span class="body4 sub3">/</span>
              <span class="body4 sub3 margin-left-4">월</span>
            </div>
            <div class="h7 main" v-else>{{ item.price_text }}</div>
          </div>
          <div class="body2 sub3 padding-top-12">
            <div class="body4">{{ item.desc }}</div>
            <div class="body4">{{ item.name==='BASIC'? '최초 개설 시 추천':'' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="flex-align margin-bottom-16">
      <div class="h8 main">호스팅</div>
      <div class="body5 error margin-left-4">*가입 필수 / 추후 호스팅 단계 변경 가능</div>
    </div>
      <div class="body5 sub3">서비스를 가동하는데 필요한 서버 및 관리 비용에 해당하는 비용으로 AWS 서비스 기반의 지속성과 안정성을 보장합니다.</div>
      <div class="card-wrapper margin-top-4">
        <div class="card-info unselect"
             :class="{'selected-card': inputValue && item.id === inputValue.id}"
             v-for="item in list"
             :key="`hosting-${item.id}`"
             @click="selectItem(item)">
          <div class="flex-between">
            <div class="body5-medium">{{ item.name }} <span v-if="item.price_type===0">(월결제)</span></div>
            <div>
              <div v-if="item.price_type===0">
                <span class="body3-bold main">{{ item.price | currencyNum }}</span>
                <span class="body5 main">원</span>
                <span class="body5 sub3">/월</span>
              </div>
              <div class="body3-bold main" v-else>{{ item.price_text }}</div>
            </div>
          </div>
          <div class="body2 sub3 padding-top-8">
            <div class="body5">{{ item.desc }}</div>
            <div class="body5">{{ item.name==='BASIC'? '최초 개설 시 추천':'' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SelectHosting",
    props: {
      value: {
        type: Object
      }
    },
    created() {
      this.getData();
    },
    data() {
      return {
        list: [],
        inputValue: undefined
      }
    },
    methods: {
      selectItem(item) {
        this.inputValue = item;
        this.$emit('update:value', this.inputValue);
      },
      getData() {
        this.$axios.get('public/launchpack/hosting').then(res=>{
          this.list = res.data;
          if(this.list.length>0) this.inputValue = this.list[0];
          this.$emit('update:value', this.list[0]);
        })
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(3, 1fr)
    .card-info
      border 2px solid $gray1
      padding 32px
      border-radius 8px

  .mobile
    .card-info
      border 1px solid $gray1
      padding 12px 16px
      border-radius 8px
      margin-top 12px

  .introduction-content
    white-space pre-line

  .selected
    color $primary

  .selected-card
    border 2px solid $primary !important

</style>
