<template>
  <div>
    <div class="container-top">
      <div class="pc container padding-top-60 position-relative">
        <div class="h5 main">결제하기</div>
        <div class="flex padding-top-52" style="align-items: flex-start">
          <!-- 서비스 정보 -->
          <div class="col-9" style="padding-right:74px">
            <div class="flex-align" style="gap:12px">
              <img :src="order.order_product.product.img" class="img-product"
                   :alt="order.order_product.product.name">
              <div>
                <div class="subtitle5 sub">{{ order.order_product.product.name }}</div>
                <div v-if="order.order_product.order_product_option.length>0"
                     class="body4 sub3">{{ order.order_product.order_product_option[0].label }}</div>
                <div class="h7 main">{{ order.order_product.product_price|currencyNum }}
                <span class="body4 sub">원</span></div>
              </div>
            </div>

            <!-- 수정 요청 주문서 -->
            <skin-inquiry v-if="enableInquiry"
              :ordering="true" :value.sync="order.inquiry"></skin-inquiry>

            <!-- 호스팅 -->
            <select-hosting :value.sync="order.hosting"></select-hosting>

            <!-- 솔루션 이용 옵션 -->
            <select-care-service :value.sync="order.care_service"></select-care-service>

            <div class="lp-divider-main" style="padding-top:96px"></div>
            <div class="body0-bold main margin-top-16">결제 방법</div>
            <div class="body2 sub3 margin-top-8">매월 서비스 이용료 및 부가서비스 비용을 납부할 결제 방식입니다.</div>
            <div class="lp-divider-gray2 padding-top-16 margin-bottom-32"></div>

            <billing-form ref="form" :service_id="serviceId"></billing-form>
            <div style="margin-top:24px">
              <lp-check-box-array :value.sync="agree" :items="checkItem"></lp-check-box-array>
            </div>
          </div>

          <div class="col-3 price-info-wrapper" :class="`${sticky}`">
            <div class="h8 main">결제금액</div>
            <div class="lp-divider-gray1 padding-top-16"></div>

            <div class="flex-between padding-top-16">
              <div class="subtitle7">스킨</div>
              <div>
                <span class="body4-bold">{{ order.order_product.product_price | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>
            <template v-if="order.isServiceUsePrice">
              <div class="lp-divider-gray3 padding-top-16"></div>
              <div class="flex-between padding-top-16">
                <div class="subtitle7">서비스 이용료</div>
                <div class="flex-align margin-left-28">
                  <div class="body4-bold">{{ order.service_use_price.price | currencyNum }}</div>
                  <span class="body4 sub">원</span>
                  <span class="body4 sub3">/월</span>
                </div>
              </div>
            </template>
            <template v-if="order.order_product.add_option_price>0">
              <div class="lp-divider-gray3 padding-top-16"></div>
              <div class="flex-between padding-top-16">
                <div class="subtitle7">추가옵션</div>
                <div>
                  <span class="body4-bold">{{ order.order_product.add_option_price | currencyNum }}</span>
                  <span class="body4 main">원</span>
                </div>
              </div>
            </template>
            <template v-if="enableInquiry">
              <div class="lp-divider-gray3 padding-top-16"></div>
              <div class="flex-between padding-top-16">
                <div class="subtitle7">수정개발</div>
                <div class="body4-bold">견적확인 후 별도 청구</div>
              </div>
            </template>
            <div class="lp-divider-gray3 padding-top-16"></div>
            <div class="flex-between padding-top-16">
              <div class="subtitle7">호스팅</div>
              <div>
                <span class="body4-bold">+{{ hostingPrice | currencyNum }}</span>
                <span class="body4 main">원</span><span class="body4 sub3" v-if="order.type !== 'quickbuilder'">/월</span>
              </div>
            </div>
            <div class="lp-divider-gray3 padding-top-16"></div>

            <div class="flex-between padding-top-16">
              <div class="subtitle7">솔루션 이용 옵션</div>
              <div>
                <span class="body4-bold">+{{ carePrice | currencyNum }}</span>
                <span class="body4 main">원</span><span class="body4 sub3" v-if="order.type !== 'quickbuilder'">/월</span>
              </div>
            </div>
            <div class="lp-divider-gray3 padding-top-16"></div>

            <div class="flex-between padding-top-16">
              <div class="subtitle7">VAT 10% 별도</div>
              <div>
                <span class="body4-bold">+{{ vat | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>


            <div class="lp-divider-gray1 padding-top-56"></div>
            <div class="flex-between padding-top-20">
              <div class="body3-medium">총 금액</div>
              <div>
                <span class="h6">{{ totalPrice | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>
            <div v-if="subscribePrice>0"
              class="box-subscribe">*첫 결제 후, 서비스 개설 완료 한 달 뒤<br>부터 <b>월 {{ subscribePrice|currency }}</b>
              이 자동결제됩니다. </div>
            <button class="button is-primary margin-top-16" style="width:100%" @click="clickOrder">결제하기</button>
          </div>
        </div>
      </div>
      <div class="mobile position-relative">
        <div class="h7 main lp-divider-gray1 mo-container"
             style="padding-bottom:16px">결제하기</div>

        <div class="mo-container">
          <div class="h8 main margin-bottom-16">서비스 정보</div>
          <div class="flex-between margin-bottom-12">
            <div class="body4 sub">{{ order.order_product.product.name }}</div>
            <div class="body4-bold main">{{ order.order_product.product.price.price|currencyNum }}
              <span class="body4 sub">원</span></div>
          </div>
          <div class="flex-between" v-if="order.isServiceUsePrice">
            <div class="body4 sub">서비스 운영 이용료</div>
            <div class="body4-bold main">{{ order.service_use_price.price | currencyNum }}
              <span class="body4 sub">원</span></div>
          </div>
        </div>

        <div class="mo-container">
          <!-- 수정 요청 주문서 -->
          <skin-inquiry v-if="enableInquiry"
                        :ordering="true" :value.sync="order.inquiry"></skin-inquiry>
        </div>
        <div class="mo-container">
          <!-- 호스팅 -->
          <select-hosting :value.sync="order.hosting"></select-hosting>
        </div>
        <div class="mo-container">
          <!-- 솔루션 이용 옵션 -->
          <select-care-service :value.sync="order.care_service"></select-care-service>
        </div>

        <div class="mo-container">
          <div class="h8 main margin-bottom-16">결제 금액</div>
          <div class="flex lp-divider-gray1 margin-bottom-12"
               style="flex-direction: column;gap:12px;padding-bottom:12px">
            <div class="flex-between">
              <div class="body4 sub">스킨</div>
              <div>
                <span class="body4-bold">{{ order.order_product.product_price | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>
            <div class="flex-between" v-if="order.isServiceUsePrice">
              <div class="body4 sub">서비스 운영 이용료</div>
              <div>
                <span class="body4-bold">{{ order.service_use_price.price | currencyNum }}</span>
                <span class="body4 main">원</span>
                <span class="body4 sub3">/월</span>
              </div>
            </div>
            <div class="flex-between">
              <div class="body4 sub">호스팅 비용 </div>
              <div>
                <span class="body4-bold">{{ hostingPrice | currencyNum }}</span>
                <span class="body4 main">원</span>
                <span class="body4 sub3">/월</span>
              </div>
            </div>
            <div class="flex-between">
              <div class="body4 sub">솔루션 이용 옵션 </div>
              <div>
                <span class="body4-bold">{{ carePrice | currencyNum }}</span>
                <span class="body4 main">원</span>
                <span class="body4 sub3">/월</span>
              </div>
            </div>
            <div class="flex-between">
              <div class="body4 sub">VAT 10% 별도</div>
              <div>
                <span class="body4-bold">{{ vat | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>
          </div>
          <div class="flex-between">
            <div class="body4-medium main">총 결제금액</div>
            <div>
              <span class="h7 main">{{ totalPrice | currencyNum }}</span>
              <span class="body2-medium sub">원</span>
            </div>
          </div>
          <div v-if="subscribePrice>0" class="box-subscribe">
            <img class="svg-primary" src="/static/icon/fi_alert-circle.svg" style="width:20px;height:20px;margin-right:6px"/>
            <div>첫 결제 후, 서비스 개설 완료 한 달 뒤부터<br> <b>월 {{ subscribePrice|currency }}</b>
            이 자동결제됩니다.</div>
          </div>
        </div>

        <div class="mo-container">
          <div class="h8 main margin-bottom-16">결제 방법</div>
          <billing-form ref="mform"></billing-form>
          <div style="margin-top:24px">
            <lp-check-box-array
              :value.sync="agree" :items="checkItem"></lp-check-box-array>
          </div>
          <button class="button is-primary margin-top-16" style="height:48px;width:100%" @click="clickOrder">결제하기</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SelectHosting from "../component/SelectHosting";
  import SelectCareService from "../component/SelectCareService";
  import OrderMixin from "../../mixins/OrderMixin";
  import PageMixin from "../../mixins/PageMixin";
  import BillingForm from "../module/BillingForm";
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import SkinInquiry from "../component/SkinInquiry";
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";

  export default {
    name: "SkinOrder",
    mixins: [
      OrderMixin,
      PageMixin,
      UserAPIMixin,
      ServiceAdminAPIMixin
    ],
    components: {
      SkinInquiry,
      LpCheckBoxArray,
      BillingForm,
      SelectCareService,
      SelectHosting
    },
    created() {
      this.login_required = true;
      this.init();
    },
    mounted() {
      this.$nextTick(()=>{
        this.ev = ()=> {
          let el = document.getElementById('service-info');
          if(el && el.getClientRects().length > 0) {
            let top = el.getClientRects()[0].top;
            if(top < 80) {
              this.sticky = 'sticky';
              let infoEl = document.getElementById('info').getClientRects()[0];
              if((infoEl.top - 300) < 80) {
                this.sticky = 'sticky-end';
              } else {
                this.sticky = 'sticky';
              }
            } else {
              this.sticky = '';
            }
          }
        };
        window.addEventListener('scroll', this.ev);
      })
    },
    data() {
      return {
        agree: false,
        checkItem: [
          {
            label: '구매 정보 및 결제 내역을 확인했으며, 이에 동의합니다.',
            labelStyle: {
              color: '#4f4f4f',
              fontSize: '15px'
            }
          }
        ],
        ev: undefined,
        sticky: '',
        order: {
          key: '',
          totalPrice: 0,
          subscribe_price: 0,
          hosting: undefined,
          care_service: undefined,
          service_use_price: undefined,
          service_name: '',
          name: '',
          phone: '',
          email: '',
          isServiceUsePrice: false,
          inquiry: {
            category_id: 0,
            content: '',
            imgs: []
          }
        }
      }
    },
    beforeDestroy() {
      document.getElementById('ch-plugin').style.display = '';
    },
    methods: {
      init() {
        setTimeout(()=>{
          document.getElementById('ch-plugin').style.display = 'none';
        },500);
        let basket = this.$store.getters.basket;
        if(basket === undefined) {
          this.toast('올바른 접근이 아닙니다.');
          this.$router.back();
          return;
        }

        this.order.order_product = basket.order_product;
        this.order.service_use_price = this.order.order_product.product.service_use_price
        this.order.subscribe_price = this.subscribePrice;
        this.order.totalPrice = this.order.order_product.total_price; // + (this.order.order_product.total_price/10);
        this.order.isServiceUsePrice = basket.isServiceUsePrice;

        this.order.name = this.user.name;
        this.order.email = this.user.email;
        this.order.phone = this.user.phone;
      },
      async clickOrder() {
        if(!this.agree) {
          this.toast('동의 후 진행해주세요.');
          return;
        }
        let basket = this.cloneItem(this.order);
        basket.price = this.totalPrice;
        basket.subscribe_price = this.subscribePrice;
        basket.title = basket.order_product.product.name;

        if(this.enableInquiry && this.order.inquiry.category_id === 0) {
          this.toast('수정 요청 종류를 선택해주세요.');
          return;
        }

        if(this.billingForm.selectedBill) {
          basket.bill = this.billingForm.selectedBill.id;
        } else {
          if(!this.validate()) {
            this.toast('카드 정보를 제대로 입력해주세요.');
            return;
          }
          basket.credit = this.billingForm.value;
        }
        this.setLoading();
        this.request.serviceAdmin.post(`launchpack/v1/service/${this.$store.getters.admin.service_id}/skin_account`, { order: basket }).then(res => {
          if(res.status === 200) {
            this.clearLoading();
            basket.credit = res.data.detail.credit;

            this.$store.commit('clearBasket');
            this.$store.commit('setAccountDone', basket);
            this.$router.replace('/skin_account_done');
          }
        }).catch(error => {
          this.clearLoading();
          if (error.response) {
            let res = error.response;
            //this.toast(res.data.message);
            this.$router.push(`/account_fail?reason=${res.data.message}`);
          }
        })

      },
      validate() {
        return !(Object.keys(this.billingForm.value).some(key => this.billingForm.value[key] === ''));
      },
    },
    computed: {
      serviceId() {
        return this.$store.getters.admin ? this.$store.getters.admin.service_id : 0;
      },
      enableInquiry() {
        return this.order.order_product.order_product_option.length> 0 &&
               this.order.order_product.order_product_option[0].label.indexOf('요청')>-1;
      },
      isMobile() {
        return window.innerWidth < 1024;
      },
      billingForm() {
        return this.isMobile ? this.$refs.mform : this.$refs.form;
      },
      subscribePrice() {
        return this.hostingPrice + this.carePrice;
      },
      hostingPrice() {
        return this.order.hosting ? this.order.hosting.price : 0;
      },
      carePrice() {
        return this.order.care_service ? this.order.care_service.price : 0;
      },
      totalOrgPrice() {
        let price = this.hostingPrice + this.carePrice;
        if(this.order.isServiceUsePrice) {
          price += this.order.service_use_price.price;
        }
        //price += price/10;
        price += this.order.totalPrice;
        return price;
      },
      totalPrice() {
        return this.totalOrgPrice + this.vat;
      },
      vat() {
        //return this.totalPrice * 1/11;
        return this.totalOrgPrice / 10;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .service-info-title
      width 40%

    .service-info-content
      width 60%

    .price-info-wrapper
      position sticky
      top 84px
      border 1px solid $gray2
      border-radius 8px
      padding 24px
      box-shadow 0 10px 20px rgba(0, 0, 0, 0.05)
    .sticky
    .sticky-end
      left calc(50% + 288px)
      background-color white
      width 288px
    .sticky
      position fixed
      top 104px

    .sticky-end
      position absolute
      bottom 0

  .img-product
    width 140px
    height 105px
    object-fit cover
    object-position center
    border-radius 6px

  .box-subscribe
    margin-top 8px
    padding 8px 10px
    border-radius 8px
    font-size 13px
    line-height 20px
    color $sub3
    background-color $gray3

  .mo-container
    padding 24px 16px
    border-bottom 8px solid $gray2
    .box-subscribe
      margin-top 16px
      padding 12px 16px
      display flex
      align-items center
      gap 14px
      color $sub2
      font-size 15px
      b
        font-size 16px

  .mo-container:last-child
    border-bottom 0
</style>
